.calendar-event {
  border-radius: 0.125rem;
  border: none;
  cursor: pointer;
  /* font-size: 0.8125rem; */
  padding: 5px;
  text-align: center;
  background-color: #e2edd9;
  color: #373b31;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.first-time-appt {
  background-color: #e2edd9;
  color: #373b31;
  padding: 1px;
}
.cursor-pointer {
  cursor: pointer;
}

.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}


/* Clash starts here */

.fc-icon-chevron-left:before,.fc-icon-chevron-right:before {
  color: #333;
}
.fc .fc-toolbar-title {
  font-size: 1.4rem!important;
}

a.fc-daygrid-more-link.fc-more-link {
  background: #D2F0FF;
  color: #333;
  padding: 4px;
  border-radius: 2px;
}


.fc-v-event {
  display: block;
  border: none;
  /* border: 1px solid var(--fc-event-border-color, #9ad837); */
  /* background-color: #9ad837;
  background-color: var(--fc-event-bg-color, #3a3e42); */
  background-color: #e2edd9;
  color: #373b31;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #fff;
}
.fc .fc-timegrid-slots {
  background-color: #fff;
}
.fc .fc-button-primary {
  background-color: #e2edd9;
  color: #373b31;
}


button.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active,
button.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active,
button.fc-timeGridDay-button.fc-button.fc-button-primary.fc-button-active {
  background-color: bisque;
  color: black;
  background: rgba(47, 165, 219, 0.6)!important;
}
.fc .fc-button-primary:hover {
  color: #373b31;
  color: var(--fc-button-text-color, #373b31);
}

.fc .fc-button-primary:focus {
  box-shadow: 0 0 0 0.2rem rgb(76 91 106 / 0%);
}
.fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: 0 0 0 0.2rem rgb(192 228 244);
}
.fc .fc-button-group > .fc-button:hover {
  z-index: 1;
}